import React, { useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Gallery from '../components/Gallery';
import Grid from '../components/Grid';
import { processGrid, processGallery } from '../util/processImages';

const PortfolioSingle = ({ pageContext }) => {
  const [open, setOpen] = useState();
  const [index, setIndex] = useState();
  const clickGrid = (index) => {
    setIndex(index);
    setOpen(!open);
  }

  return (
    <div>
      <h2>{pageContext.title}</h2>
      <p>{pageContext.description}</p>
      {pageContext.body ? documentToReactComponents(pageContext.body.json) : null}
      <Grid
        images={processGrid(pageContext.images)}
        onClick={clickGrid}
      />
      <Gallery
        images={processGallery(pageContext.images)}
        open={open}
        toggleOpen={() => setOpen(!open)}
        index={index}
      />
    </div>
  );
};

export default PortfolioSingle;
