import React from 'react';
import Gallery from 'react-photo-gallery';
import Img from 'gatsby-image';

const containerStyle = {
  backgroundColor: '#eee',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
}

const Grid = ({ images, onClick }) => {

  const Image = ({ photo, index, margin }) => {
    return(
    <div
      key={index}
      onClick={() => onClick(index)}
      style={{
        margin,
        height: photo.height,
        width: photo.width,
        ...containerStyle,
      }}
    >
      <div className="grid-overlay" />
      <Img fixed={photo} />
    </div>
  );
  }

  return (
    <Gallery
      photos={images}
      renderImage={Image}
    />
  );
};


export default Grid;
