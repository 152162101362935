import PropTypes from 'prop-types';
import React from 'react';
import Carousel, { Modal, ModalGateway } from "react-images";
import Img from 'gatsby-image';
import left from '../assets/images/carousel-left-gray.png';
import right from '../assets/images/carousel-right-gray.png';

const View = ({ data }) => (
    <Img
      fluid={data}
      objectFit="contain"
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100vw',
        height: '100vh',
        position: 'relative',
        textAlign: 'center',
      }}
      imgStyle={{
        objectFit: 'contain',
      }}
    />
);

const btnStyle = {
  position: 'fixed',
  height: '100vh',
  width: '125px',
  top: 0,
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  background: 'none',
};

const Next = ({ innerProps: { onClick } }) => (
  <button
    onClick={onClick}
    style={{
      right: 0,
      ...btnStyle,
    }}
  >
    <img
      src={right}
      alt=""
      style={{
        width:'100%',
      }}
    />
  </button>
);

const Prev = ({ innerProps: { onClick } }) => (
  <button
    onClick={onClick}
    style={{
      left: 0,
      ...btnStyle,
    }}
  >
    <img
      src={left}
      alt=""
      style={{
        width:'100%',
      }}
    />
  </button>
);

const Gallery = ({ images, open, toggleOpen, index }) => (
  <ModalGateway>
    {open ? (
      <Modal onClose={toggleOpen}>
        <Carousel
          currentIndex={index}
          views={images}
          components={{
            View,
            NavigationNext: Next,
            NavigationPrev: Prev,
          }}
        />
      </Modal>
    ) : null}
  </ModalGateway>
);

Gallery.displayName = 'Gallery';
Gallery.propTypes = {
    images: PropTypes.array
};

export default Gallery;