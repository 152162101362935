
export const processGrid = (images) => {
  const processed = [];
  images.forEach(image => {
    if (image.file.contentType.includes('image')) {
      processed.push(image.fixed);
    }
  });
  return processed;
}

export const processGallery = (images) => {
  const processed = [];
  images.forEach(image => {
    if (image.file.contentType.includes('image')) {
      processed.push(image.fluid);
    }
  });
  return processed;
}
